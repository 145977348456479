// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

html body {
  display: inherit !important;
}

body {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.65)
  ), url('./assets/img/soccerStock.png');
  background-repeat: no-repeat;
  background-size:cover;
}

.body-background-colour {
  background: var(--ce-background-colour) !important;
  background-image: none !important;
}

.green-border-r {
    border-right-color: var(--ion-color-success);
    border-right-style: solid;
    border-right-width: 9px;
}

.green-border-l {
    border-left-color: var(--ion-color-success);
    border-left-style: solid;
    border-left-width: 9px;
}

.red-border-r {
    border-right-color: var(--ion-color-danger);
    border-right-style: solid;
    border-right-width: 9px;
}

.red-border-l {
    border-left-color: var(--ion-color-danger);
    border-left-style: solid;
    border-left-width: 9px;
}

.yellow-border-r {
    border-right-color: var(--ion-color-warning);
    border-right-style: solid;
    border-right-width: 9px;
}

.yellow-border-l {
    border-left-color: var(--ion-color-warning);
    border-left-style: solid;
    border-left-width: 9px;
}

.content-ios:not([no-bounce])>.scroll-content::before { bottom: 0; }

.split-pane-visible>ion-menu.split-pane-side.menu-enabled {
  max-width: 300px;
}

.side-img {
    margin-top: 20px;
    display: block;
    // width: 40%;
    height: auto;
    max-height: 64px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

.menu-text {
       color: white;
    // text-align: center;
    font-size: large;
    font-weight: bold;
}

.role-text {
  color: white;
    // text-align: center;
    font-size: medium;
    font-weight: lighter;
}

#logout-button {
    height: 40px;
    font-size: 12pt;
    width:80%;
     margin-left: auto;
    margin-right: auto;
}

ion-header {
  --background: none;
  background: var(--ce-background-colour);
}

ion-menu {

    .scroll-content {
        display: flex;
        flex-direction: column;
    }
    ion-grid {
        padding: 0;
        height: 100%;
    }

    ion-row {
        flex: 1;
    }

}

.side-menu-image {

 padding-top:env(safe-area-inset-top);
  background-repeat: no-repeat;
  background-position-y: 30%;
    background-size:cover;


  ion-toolbar {
      --background: none;
      background-color: transparent;
  }

  ion-item {
    --background: none;
    background-color: transparent;
  }
  .item-button {
    margin-top: 45px;
    margin-bottom: 10px;
  }
}

  ion-note.version-text{
    margin-top: auto;
    text-align: center;
    p {
      margin-block-end: 5px;
      margin-block-start: 5px;
    }
  }

.side-menu-item {
  background: none;
  // color: white;
  font-weight: 300;
  border: 0px;

  &.selected {
    font-weight: 600;
    --background: none;
    background-color: #c2d8ff;
  }
}

ezy-fab {
    right: 15px;
    bottom: 15px;
    ion-fab { position: static; }
}


.background{
background-repeat: no-repeat;
    background-size:cover;
}

.back-button-ios {
  z-index: 99;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.side-menu-wheel {
      // padding: 6px;

  z-index: 10;
  box-shadow: 1px 0 5px grey;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ion-item {

      filter: grayscale(100%);
      --background: none;
      --inner-padding-end: 0;

      ion-label {
        margin: 0;
      }

  }
  .selected {
      filter: grayscale(0) !important;
      --background: none;
      background-color: #c2d8ff;
      img {
        opacity: 1 !important;
      }
      // -webkit-filter: grayscale(0) !important;
  }

  ion-avatar {
    // display: flex;
    flex-direction: column;    /* align flex items vertically */
    // justify-content: center;   /* center items vertically, in this case */
    align-items: center;       /* center single-line items horizontally, in this case */
    // align-content: center;     /* center multi-line items horizontally, in this case */
    img {
      width: inherit;
      height: inherit;
      border-radius: 0;
      opacity: 0.8;
    }

  }
}

.side-menu-lists {
  padding-top:16px;
  overflow-y: auto;
  z-index: 9;
}

.org-select {
  width: 100%;
  // height: 0;
  height: 100%;
  // -webkit-transition: 190ms height ease-out;
  // -moz-transition: 190ms height ease-out;
  // -o-transition: 190ms height ease-out;
  // transition: 190ms height ease-out;
  background-color: var(--ce-white);
  // position: absolute;
  // top: 0;
  // left: 0;
  overflow-y: scroll;
  // z-index: 11;
}

.org-select-expanded {
  // height: 100%;
  // -webkit-transition: 190ms height ease-out;
  // -moz-transition: 190ms height ease-out;
  // -o-transition: 190ms height ease-out;
  // transition: 190ms height ease-out;
}

.org-select-wrap {
  width: 100%;
  position: absolute;
  // overflow-y: scroll;
  z-index: 11;
  height: 100%;
  // transition: 0.2s ease-out;
  // height: 0;
  // transform: translateY(-100%);
}

.org-select-wrap-expanded {
  // height: 100%;
  // transform: translateY(0);
}


.badge-wrap {
  position: relative;
  overflow: visible !important;
}

.overlay-badge {
  right: 1rem;
  position: absolute;
  bottom: 23px;
  filter: grayscale(0) !important;
}

.height100 {
  height:100%;
}


.stacked-seperated {
    padding-left: 16px;
    padding-top:5px;
    color: --ce-font-colour;
    font-size: 1.2rem;
}

ion-card {
  background-color: var(--ion-item-background,#fff) !important;
}

ion-content .scroll-content {
    overflow-y: auto;
}

.italic {
  font-style: italic !important;
}

#jsd-widget {
  --hide-help: 1;
  --help-events: all;

  opacity: var(--hide-help);
  pointer-events: var(--help-events);

  @media only screen and (min-width: 768px){
    margin-bottom: 70px;
  }


}

ion-item-divider {
  --background: none;
  border: none;
  color: var(--ion-color-dark);
}

.message-sender {
  // border-radius: 40px !important;
  // border: var(--ion-color-medium) 1px solid !important;
  --background: none;
  background-color: var(--ce-background-colour);
  ion-item {
    // border-radius: 40px !important;
    --background: none;
    --highlight-height: 0px !important;
  }
  .textarea-input-wrapper {
    // border-radius: 40px !important;
    border: var(--ce-background-colour) 1px solid !important;
    background-color: var(--ce-white);
  }
  .textarea-send-button {
    --padding-start: 5px;
    --padding-end: 7px;

    margin-bottom: auto;
    margin-top: auto;
  }
  .inner-item {
    --background: none;
  }
}

.readable-message {
  white-space: pre-line;
}

.upload-img {
  max-height: 400px;
  margin-left: auto;
  margin-right: auto;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  position: fixed;
  height:20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin-top: -300px; /* minus half the height */
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.pre-load {
  // --background: none;
  // background-image: linear-gradient(
  //   rgba(0, 0, 0, 0.65),
  //   rgba(0, 0, 0, 0.65)
  // ), url('./assets/img/soccerStock.png');
  // background-repeat: no-repeat;
  // background-size:cover;
  height: 100%;
  width: 100%;
}

.logo-img {
 display: block;
  width: 130px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px
}

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.item ion-thumbnail {
  min-width: 5rem;
  min-height: 5rem;
  img {
      max-width: 5rem;
      min-width: 5rem;
  }
}

.modal-fullscreen {
  .modal-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
  }
}
.ios .modal-fullscreen {
  .modal-wrapper {
      top: 26px;
  }
}

.fakeInput {
  width: 100%;
  border: none !important;
  outline: none !important;
}

.alert-radio-label, .alert-checkbox-label {
  white-space: pre-line !important;
}

ion-footer {
  --background: none;
  background: var(--ce-background-colour);
}

ion-select.no-label {
  min-width: 100%;
  padding-left: 0;
}

.swiper-pagination-bullet {
  background: var(--ion-color-dark-tint);
  opacity: 0.5;
}
.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
  opacity: 1;
}
.map {
  height: 100%;
  width: 100%;
}

ion-content .the-padder {
  height: 90px;
}

ion-datetime {
  height: auto;
  width: auto;

  max-width: 350px;
}

ion-modal ion-datetime {
  height: 382px;
}

.ion-modal-datetime {
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

@media(pointer: fine) {
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888
  }
  .inner-scroll {
    scrollbar-width: thin
  }
}
